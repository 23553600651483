import { useState } from "react";
import { useParams, useNavigate, generatePath, Link } from "react-router-dom";
import { briefPropTypes, useEnterBriefMutations, briefStatusContent } from "features/brief";
import { ScrollPopup, Alert, Button, Accordion, Spinner } from "ui";
import { useProtectedAction } from "features/authentication";
import { ErrorToast } from "features/report";
import { toast } from "react-toastify";
import { motion } from "framer-motion";
import routes from "default/routes";
import PrivateBriefNDAModal from "features/brief/components/modal/PrivateBriefNDAModal";
import Markdown from "react-markdown";
import rehypeRaw from "rehype-raw";
import DOMpurify from "dompurify";
import Currency from "utils/currency";
import PropTypes from "prop-types";

// You can change the properties and order of the brief here.
const sections = [
  { title: "The Brief", attribute: "description" },
  { title: "Key Deliverables", attribute: "deliverables" },
  {
    title: "Submission Guidelines",
    attribute: "things_to_consider",
  },
  {
    title: "Selection Criteria",
    attribute: "selection_criteria",
  },
  {
    title: "Incentives",
    attribute: "other_incentives",
  },
];

const fileType = PropTypes.shape({
  id: PropTypes.number.isRequired,
  order_id: PropTypes.number.isRequired,
  type: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  file: PropTypes.shape({
    url: PropTypes.string.isRequired,
  }).isRequired,
});

function BriefFiles({ files }) {
  return (
    <div className="flex gap-3 flex-col mb-3">
      {files
        .sort((a, b) => a.order_id - b.order_id)
        .map((file) => (
          <a
            download
            href={file.file.url}
            className="bg-default-200 rounded-3xl p-3 capitalize flex justify-between items-center"
            key={file.id}
          >
            {file.description || `${file.type} File`}

            <Button color="success" size="sm">
              Download
            </Button>
          </a>
        ))}
    </div>
  );
}
BriefFiles.propTypes = {
  files: PropTypes.arrayOf(fileType).isRequired,
};

export default function BriefDetails({ brief, isLocked }) {
  const { id } = useParams();
  const [showNDAModal, setShowNDAModal] = useState(false);
  const navigate = useNavigate();
  const {
    enterBrief: { mutate, isPending: enterBriefLoading },
  } = useEnterBriefMutations();
  const redirectToPath = generatePath(routes.brief, { id });
  const { callToAction } = briefStatusContent(brief)[brief.status];

  const [enterBrief] = useProtectedAction(
    (briefUrlId) => {
      mutate(
        { id: briefUrlId },
        {
          onSuccess: (_, { id: briefId }) => {
            if (briefId !== id) {
              navigate(redirectToPath, {
                replace: true,
                preventScrollReset: true,
              });
            }
          },
          onError: (error) => {
            toast(
              <ErrorToast
                errorMessage={error.details.message}
                errorProps={{
                  defaultReason: "issue",
                  defaultPage: "enter_brief",
                  apiError: error.details,
                }}
              />,
              {
                limit: 1,
              },
            );
          },
        },
      );
    },
    {
      actionName: "enterBrief",
      redirectToPath,
    },
  );

  const handleEnterOrShowNDA = () => {
    if (brief.type === "private") {
      setShowNDAModal(true);
      return;
    }

    enterBrief(id);
  };

  return (
    <div data-testid="brief-overview" className="relative">
      <Accordion variant="splitted" className="gap-3 px-0">
        {sections
          .filter((s) => brief[s.attribute])
          .map((section) => (
            <Accordion.AccordionItem
              key={section.title}
              title={section.title}
              classNames={{
                base: "shadow-none bg-content2 rounded-3xl",
                trigger: "relative",
                title: "text-2xl",
              }}
            >
              <Markdown className="old-markdown" rehypePlugins={[rehypeRaw]}>
                {DOMpurify.sanitize(brief[section.attribute])}
              </Markdown>
            </Accordion.AccordionItem>
          ))}

        {brief.files.length > 0 && (
          <Accordion.AccordionItem
            title="Helpful Resources"
            classNames={{
              base: "shadow-none bg-content2 rounded-3xl",
              trigger: "relative",
              title: "text-2xl",
            }}
          >
            <BriefFiles files={brief.files} />
          </Accordion.AccordionItem>
        )}

        {brief.prize_money && brief.prize_money > 0 && (
          <Accordion.AccordionItem
            title="Prize"
            classNames={{
              base: "shadow-none bg-content2 rounded-3xl",
              trigger: "relative",
              title: "text-2xl",
            }}
          >
            <div className="flex items-end gap-2 mb-3">
              <span className="text-7xl">
                {Currency.get(brief.currency).format(brief.prize_money)}
              </span>
              <span>To be paid by {brief.organisation.name}.</span>
            </div>

            {brief.status === "completed" && (
              <Alert className="font-roman text-sm">
                The live competition for this brief has ended, and all prize money has been given to
                the winners.
              </Alert>
            )}
          </Accordion.AccordionItem>
        )}
      </Accordion>

      {/* we cannot prevent default accordion event, so we overlay a button instead */}
      {isLocked && (
        <div
          className="absolute h-full w-full top-0"
          onClick={() => handleEnterOrShowNDA()}
          role="button"
          tabIndex={0}
          aria-label="unlock"
        />
      )}

      {/* loading animation */}
      {enterBriefLoading && (
        <motion.div
          initial={{ opacity: 0 }}
          exit={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.2 }}
          className="bg-primary/20 h-full w-full -top-0 left-0 fixed z-50 flex items-center justify-center"
        >
          <Spinner
            size="lg"
            label="Unlocking details..."
            classNames={{
              label: "text-primary-foreground",
              circle1: "border-b-primary-foreground",
              circle2: "border-b-primary-foreground",
            }}
          />
        </motion.div>
      )}

      {callToAction && brief.entered && (
        <ScrollPopup>
          <Button
            color="primary"
            as={Link}
            to={callToAction.path}
            trackingName={callToAction.trackingName}
            fullWidth
          >
            {callToAction.text}
          </Button>
        </ScrollPopup>
      )}

      <PrivateBriefNDAModal
        brief={brief}
        onConfirm={() => {
          enterBrief(id);
          setShowNDAModal(false);
        }}
        onHide={() => setShowNDAModal(false)}
        show={showNDAModal}
      />
    </div>
  );
}
BriefDetails.propTypes = {
  brief: briefPropTypes.isRequired,
  isLocked: PropTypes.bool.isRequired,
};
