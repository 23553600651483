import { useInView } from "react-intersection-observer";
import { css } from "aphrodite";
import { PageLayout } from "features/navigation";
import { SubmissionVoteBlock } from "features/submission";
import { ScrollSections, Row, Col, Container, TitleBanner } from "ui";
import animationStyles from "ui/animations";
import highlight1 from "images/uploads/zellerfeld-washable-3d-printed-shoes-designboom-10.webp";
import highlight2 from "images/uploads/round21_origin_paddle-3.jpg";
import backgroundImage from "images/instagram_grid.jpg";
import PropTypes from "prop-types";

function ScrollInViewSection({ children }) {
  const { ref, inView } = useInView();

  return (
    <div
      ref={ref}
      className={`${inView ? `${css(animationStyles.fadeInUp)} visible` : "invisible"} old-fs-20`}
    >
      {children}
    </div>
  );
}

ScrollInViewSection.propTypes = {
  children: PropTypes.node.isRequired,
};

export default function About() {
  return (
    <PageLayout
      pageName="About"
      className="text-white"
      navbarProps={{
        size: "none",
      }}
    >
      <TitleBanner
        titleComponents={["We are", "Oditi"]}
        backgroundImage={backgroundImage}
        subText={
          <>
            We believe the best ideas can come from anyone, anywhere.
            <br />
            Co-create alongside the world&#39;s most iconic brands.
            <br />
            <b>#YourFutureStartsNow</b>
          </>
        }
      />

      <ScrollSections>
        <ScrollSections.ScrollSection className="old-bg-offdark old-rounded-top-4 old-mt-n6" first>
          <Row className="md:flex-row-reverse" gap={6}>
            <Col xl={6} className="old-py-5">
              <div className="old-w-100 old-h-100 old-rounded-4 old-overflow-hidden old-d-flex old-align-items-center">
                <div className="old-ratio old-ratio-4x3 old-rounded-4 old-overflow-hidden">
                  <img
                    src={highlight2}
                    className="old-mb-0 old-w-100 old-object-fit-cover"
                    alt="Oditi highlight 1"
                  />
                </div>
              </div>
            </Col>

            <Col
              xl={6}
              className="old-all-mb-3 old-all-mb-lg-5 old-d-flex old-flex-column old-justify-content-center"
            >
              <h2 className="old-home-header-font">The Mission</h2>

              <ScrollInViewSection>
                <p className="old-fs-20">
                  Founded during lockdown by Adam (18), Alexandre (22), and Harry (20), Oditi
                  started as an Instagram account hosting briefs to help keep creatives inspired and
                  provide opportunities.
                </p>
                <p className="old-fs-20">
                  We believe that the best ideas can come from anyone, anywhere, and Oditi helps
                  bring those ideas to light - regardless of an applicant&#39;s age, accolades or
                  audience.
                </p>
                <p className="old-fs-20">
                  Oditi enables you to co-create with organizations. All voices have an equal chance
                  to be heard, supported, and embraced by forward thinking brands.
                </p>
              </ScrollInViewSection>
            </Col>
          </Row>
        </ScrollSections.ScrollSection>

        <ScrollSections.ScrollSection
          className="old-bg-offwhite old-text-dark old-rounded-top-4"
          last
        >
          <Row gap={6}>
            <Col xl={6} className="old-py-5">
              <div className="old-w-100 old-h-100 old-rounded-4 old-overflow-hidden old-d-flex old-align-items-center">
                <div className="old-ratio old-ratio-4x3 old-rounded-4 old-overflow-hidden">
                  <img
                    src={highlight1}
                    className="old-mb-0 old-w-100 old-object-fit-cover"
                    alt="Oditi highlight 2"
                  />
                </div>
              </div>
            </Col>

            <Col
              xl={6}
              className="old-all-mb-3 old-all-mb-lg-5 old-d-flex old-flex-column old-justify-content-center"
            >
              <h2 className="old-home-header-font">Our Commitments to You</h2>

              <ScrollInViewSection>
                <ol>
                  <li>
                    <p>
                      Create a digital space where your voice is heard on a level playing field,
                      without fear of judgement.
                    </p>
                  </li>
                  <li>
                    <p>
                      Bring creative opportunities, enabling you to showcase your talents and excel
                      in the design world.
                    </p>
                  </li>
                  <li>
                    <p>
                      To continuously listen, learn, and evolve to best serve your needs and help
                      you reach the heights you deserve.
                    </p>
                  </li>
                </ol>

                <p>
                  Join us in shaping the future of design by unlocking the untapped potential of
                  innovative thinkers worldwide. Together, we can redefine the creative landscape
                  and drive a new era of design collaboration.
                </p>
              </ScrollInViewSection>
            </Col>
          </Row>
        </ScrollSections.ScrollSection>
      </ScrollSections>

      <div className="old-bg-white old-text-secondary old-rounded-top-4 old-mt-n6 old-position-relative">
        <Container className="after:pb-12 after:block old-py-6">
          <SubmissionVoteBlock color="primary" />
        </Container>
      </div>
    </PageLayout>
  );
}
