import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { PageLayout } from "features/navigation";
import {
  SubmissionCard as DefaultSubmissionCard,
  withViewCounter,
  LeaderboardBlock,
  useSubmissions,
} from "features/submission";
import { Container, CardGrid, ScrollToTopButton, AutoScrollLoader } from "ui";

import Filters from "./Filters";

const SubmissionCard = withViewCounter(DefaultSubmissionCard);

export default function Voting() {
  const [searchParams] = useSearchParams();
  const selectedTab = searchParams.get("t");
  const { hasNextPage, isFetchingNextPage, fetchNextPage, isLoading, error, all } = useSubmissions({
    search: searchParams.get("search") || "",
    brief_id: Number(selectedTab) || [],
    sort: "ucb",
    user_type: selectedTab === "veterans" ? "veteran" : [],
    published: true,
    spotlighted: selectedTab === "spotlight" ? true : [],
  });
  const submissions = useMemo(() => {
    const seenIds = new Set();
    return all.filter((submission) => {
      if (seenIds.has(submission.id)) return false;

      seenIds.add(submission.id);
      return true;
    });
  }, [all]);

  return (
    <PageLayout
      pageName="Voting"
      showFooter={!hasNextPage}
      color="secondary-100"
      navbarProps={{
        size: "lg",
      }}
    >
      <Container id="voting" className="after:pb-12 after:block old-contain-paint">
        <Filters />

        <div className="old-mb-5">
          <CardGrid entries={submissions.slice(0, 12)} card={SubmissionCard} />

          {submissions.length > 0 && (
            <Container className="bg-content2 old-text-dark old-rounded-4 old-mb-3 old-py-3 old-py-md-6">
              <LeaderboardBlock color="primary" />
            </Container>
          )}

          <CardGrid entries={submissions.slice(12)} card={SubmissionCard} />
        </div>

        <AutoScrollLoader
          fetchNextPage={fetchNextPage}
          hasNextPage={hasNextPage}
          isLoading={isLoading || isFetchingNextPage}
          error={error}
          empty={!submissions.length}
          className="old-mb-5"
        >
          There are no submissions here at the moment.
          <br />
          Please come back at a later date.
        </AutoScrollLoader>
      </Container>

      <ScrollToTopButton />
    </PageLayout>
  );
}
