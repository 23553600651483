import { useMemo, useState } from "react";
import { useParams, Link, useSearchParams } from "react-router-dom";
import { BriefStatusModal, briefStatusContent, useBrief, briefPropTypes } from "features/brief";
import { NotFound } from "features/report";
import { PageLayout } from "features/navigation";
import { twMerge } from "tailwind-merge";
import { Row, Col, Container, ScrollToTopButton, ModalClickContainer, Tabs, Tab, Button } from "ui";
import routes from "default/routes";
import BriefCard from "default/pages/Brief/BriefCard";
import BriefDetails from "default/pages/Brief/BriefDetails";
import BriefWorkspace from "default/pages/Brief/BriefWorkspace";
import BriefSubmissions from "default/pages/Brief/BriefSubmissions";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import dayjs from "dayjs";

import PropTypes from "prop-types";

function CallToActionButton({ brief, className = "" }) {
  const { callToAction } = briefStatusContent(brief)[brief.status];

  if (!brief.entered || !callToAction) return null;

  return (
    <Button
      color="primary"
      size="lg"
      fullWidth
      as={Link}
      to={callToAction.path}
      trackingName={callToAction.trackingName}
      data-testid="brief-cta"
      className={className}
    >
      {callToAction.text}
    </Button>
  );
}
CallToActionButton.propTypes = {
  brief: briefPropTypes.isRequired,
  className: PropTypes.string,
};

function BriefDateAttribute({ title, attribute = "", last = false }) {
  if (!attribute || attribute === "") return null;
  const completed = Date.now() >= dayjs(attribute);

  return (
    <div className="flex relative" data-testid="brief-details">
      <div className="flex flex-col items-center mr-5">
        <span
          className={twMerge(
            "rounded-full !size-4 block",
            completed && "bg-content4",
            !completed && "bg-content3",
          )}
        />

        {!last && (
          <span
            className={twMerge(
              "border grow my-2",
              completed && "border-content4",
              !completed && "border-content3",
            )}
          />
        )}
      </div>
      <div>
        <h6 className="uppercase font-bold">{title}:</h6>
        <p className="text-sm">{dayjs(attribute).format("MMM. DD, HH:mm")}</p>
      </div>
    </div>
  );
}
BriefDateAttribute.propTypes = {
  attribute: PropTypes.string,
  title: PropTypes.string.isRequired,
  last: PropTypes.bool,
};

export default function Brief() {
  const { id } = useParams();
  const [finalPage, setFinalPage] = useState(true);
  const [searchParams, setSearchParams] = useSearchParams();
  const { data, isLoading } = useBrief(id);
  const brief = data ?? {};
  const isLocked =
    isLoading ||
    (!brief.entered &&
      ["scheduled", "submitting", "completed", "proposing"].includes(brief.status));

  const defaultTab = useMemo(() => {
    if (searchParams.get("tab")) return searchParams.get("tab");

    if (brief.id === null) return "details";

    if (
      ["submitting", "proposing", "selecting"].includes(brief.status) &&
      brief.user_submission_count > 0
    )
      return "workspace";

    if (["voting", "judging"].includes(brief.status)) return "submissions";
    return "details";
  }, [brief]);

  const showTabs =
    brief.user_submission_count > 0 || ["voting", "judging", "completed"].includes(brief.status);

  if (!isLoading && Object.keys(brief).length <= 0) {
    return (
      <NotFound
        text={"Bummer. \n The requested brief could not be found or does not exist."}
        issuePage="enter_brief"
      />
    );
  }

  return (
    <PageLayout
      isLoading={isLoading}
      pageName="Brief"
      meta={{
        image: brief.banner?.url || brief.thumbnail,
        description: `Oditi Brief: ${brief.title}`,
      }}
      showFooter={finalPage}
      navbarProps={{
        size: "lg",
      }}
    >
      {Object.keys(brief).length > 0 && (
        <Container className="after:pb-12 after:block mb-8">
          <BriefCard
            brief={brief}
            classNames={{
              base: "mb-5 [@media(max-height:1025px)]:hidden",
              body: "p-10",
              footer: "bottom-10 w-[calc(100%_-_80px)] ms-10",
            }}
          />

          <Row gap={6} className="gap-y-5">
            <Col xs={12} xl={4}>
              <div className="initial lg:sticky top-[88px] flex flex-col gap-5">
                <BriefCard
                  brief={brief}
                  classNames={{
                    base: "[@media(min-height:1025px)]:hidden",
                    title: "text-4xl",
                  }}
                />

                <CallToActionButton
                  brief={brief}
                  className="[@media(min-height:1025px)]:hidden hidden xl:flex my-3"
                />

                <div
                  className={twMerge(
                    "flex text-center gap-4",
                    brief?.design_interests.length <= 0 && "hidden",
                  )}
                >
                  {brief.design_interests.map((category) => (
                    <Button
                      radius="md"
                      as={Link}
                      key={category}
                      color="primary"
                      className="bg-purple"
                      to={`${routes.briefs}?category=${category}`}
                    >
                      {category.replace("_", " ")}
                    </Button>
                  ))}
                </div>

                {brief.status !== "completed" && (
                  <ModalClickContainer
                    modal={BriefStatusModal}
                    modalProps={{
                      brief,
                    }}
                    className="hidden xl:block"
                  >
                    <div className="p-4 bg-content2 rounded-3xl relative">
                      <div className="flex items-top justify-between mb-3">
                        <span className="text-sm text-default-400 uppercase mt-1">
                          Times are displayed in your browser&apos;s local time zone
                        </span>
                        <HelpOutlineIcon className="!size-8" />
                      </div>
                      <div className="mt-4">
                        <BriefDateAttribute attribute={brief.start_time} title="Launch" />
                        <BriefDateAttribute
                          attribute={brief.proposal_deadline}
                          title="Proposal Deadline"
                        />
                        <BriefDateAttribute
                          attribute={brief.proposal_selected}
                          title="Proposal Selected"
                        />
                        <BriefDateAttribute
                          attribute={brief.submission_deadline}
                          title="Submission Deadline"
                        />
                        <BriefDateAttribute
                          attribute={brief.voting_deadline}
                          title="Voting Deadline"
                        />
                        <BriefDateAttribute
                          attribute={brief.winners_selected}
                          title="Winner Announcement"
                          last
                        />
                      </div>
                    </div>
                  </ModalClickContainer>
                )}

                <CallToActionButton
                  brief={brief}
                  className="[@media(max-height:1025px)]:hidden hidden xl:flex"
                />
              </div>
            </Col>

            <Col xs={12} xl={8}>
              <Tabs
                fullWidth
                size="lg"
                radius="lg"
                color="primary"
                classNames={{
                  base: twMerge("max-w-full flex justify-center pb-5", !showTabs && "hidden pb-0"),
                  tab: "uppercase h-auto sm:py-2",
                  cursor: "rounded-2xl",
                  tabList: "p-2",
                  tabContent: "p-1",
                }}
                onSelectionChange={(tab) => {
                  setSearchParams({ tab });
                  setFinalPage(true);
                }}
                defaultSelectedKey={defaultTab}
              >
                <Tab key="details" title="Overview" className="py-0">
                  <BriefDetails brief={brief} isLocked={isLocked} />
                </Tab>

                {brief.user_submission_count > 0 && (
                  <Tab key="workspace" title="Workspace" className="py-0">
                    <BriefWorkspace brief={brief} />
                  </Tab>
                )}

                {["voting", "judging", "completed"].includes(brief.status) && (
                  <Tab key="submissions" title="Submissions" className="py-0">
                    <BriefSubmissions brief={brief} setFinalPage={setFinalPage} />
                  </Tab>
                )}
              </Tabs>
            </Col>
          </Row>
        </Container>
      )}

      <ScrollToTopButton />
    </PageLayout>
  );
}
