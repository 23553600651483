import { useState, useContext } from "react";
import { useNavigate, generatePath } from "react-router-dom";
import { Element } from "react-scroll";
import { InView } from "react-intersection-observer";
import { AuthContext } from "features/authentication";
import { useUpdateUser, UserContext } from "features/user";
import { PageLayout } from "features/navigation";
import { socialIcons } from "features/profile";
import { Container, SectionLink, Row, Col, ConfirmationModal, Button } from "ui";
import {
  Input,
  ControlledUsernameInput,
  Select,
  CountryPicker,
  ControlledTextarea,
  CheckboxGroup,
  Checkbox,
  Form,
  useForm,
  Controller,
  ControlledAvatarInput,
  ControlledSocialInput,
  handleFormError,
  DatePicker,
} from "forms";
import iso3311a2 from "iso-3166-1-alpha-2";
import routes from "default/routes";
import api from "adapters/api";
import dayjs from "dayjs";
import PropTypes from "prop-types";

const defaultSections = {
  icon: { inView: true, title: "Change Icon" },
  basic: { inView: false, title: "Basic Information" },
  about: { inView: false, title: "About" },
  links: { inView: false, title: "Links" },
  socials: { inView: false, title: "Socials" },
  marketing: { inView: false, title: "Notification Preferences" },
};

const deletedEntityKeys = {
  submission: "Submissions",
  comment: "Comments",
  vote: "Votes",
  submissionspotlight: "Spotlighted Submissions",
};

const socialFields = ["instagram", "tiktok", "facebook", "twitter", "youtube"];

function DeleteText({ entities }) {
  return (
    <>
      <p>
        <span className="old-text-danger">You are about to permanently delete your account.</span>{" "}
        This action is irreversible. Upon deletion, you will lose access to your account and all
        associated data.
      </p>

      {entities.length > 0 && (
        <>
          <p>The following data will be deleted:</p>
          <ul className="old-d-flex old-flex-column old-align-items-center old-text-left">
            {entities.map((entity) => (
              <li key={entity}>{entity}</li>
            ))}
          </ul>
        </>
      )}
      <p>This process cannot be undone. Are you sure you want to proceed?</p>
    </>
  );
}
DeleteText.propTypes = {
  entities: PropTypes.arrayOf(PropTypes.number).isRequired,
};

export default function UserEdit() {
  const { user } = useContext(UserContext);
  const { mutate, isPending } = useUpdateUser();
  const { logout } = useContext(AuthContext);
  const [sections, setSections] = useState(defaultSections);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deletedEntities, setDeletedEntities] = useState([]);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: {
      errors: { socials: socialErrors = {}, ...errors },
    },
  } = useForm({
    mode: "onChange",
  });
  const navigate = useNavigate();
  const notifications = user.metadata.settings.notification_preferences ?? {};
  const socials = user.metadata.socials ?? {};

  const prepareDeleteAccount = async () => {
    setDeleteLoading(true);
    const res = await api.delete({ url: "users/me" });
    if (res.success) {
      const entities = Object.keys(deletedEntityKeys)
        .map((entity) => {
          const count = res.data[entity];
          return count > 0 ? `${count} ${deletedEntityKeys[entity]}` : null;
        })
        .filter((entity) => entity);
      setDeleteLoading(false);
      setDeletedEntities(entities);
      setShowDeleteModal(true);
    }
  };

  const handleDeleteAccount = async () => {
    const res = await api.delete({ url: "users/me?force=true" });
    if (res.success) logout();
  };

  const setSectionInView = (section, inView) => {
    setSections({
      ...sections,
      [section]: { ...sections[section], inView },
    });
  };

  const handleEditUser = (formData) => {
    formData.metadata = {
      socials: Object.fromEntries(Object.entries(formData.socials).filter(([, v]) => v !== "")),
      settings: {
        notification_preferences: Object.fromEntries(
          Object.keys(notifications).map((n) => [n, formData.marketing.includes(n)]),
        ),
      },
    };

    if (!(formData.icon instanceof File)) delete formData.icon;
    delete formData.socials;
    delete formData.marketing;

    formData.dob = formData.dob ? dayjs(formData.dob).format("YYYY-MM-DD") : "";
    formData.country_code = iso3311a2.getCode(formData.country_code) || "";

    // Only send the data we want to update to the api, ignore blank entries
    return mutate(Object.fromEntries(Object.entries(formData).filter(([, v]) => v)), {
      onSuccess: () => navigate(generatePath(routes.userProfile, { username: formData.username })),
      onError: (error, data) => handleFormError({ error, data, setError, errorPage: "profile" }),
    });
  };

  return (
    <PageLayout pageName={["Profile", "Edit"]}>
      <Container className="old-contain-paint after:pb-12 after:block old-pt-4">
        <Form onSubmit={handleSubmit(handleEditUser)}>
          <Row gap={6}>
            <Col xs={12} lg={4} className="old-mb-lg-3">
              <div className="old-position-initial old-position-lg-sticky old-top-section-margin old-mb-6 old-mb-lg-3 old-fs-18 old-fs-md-20">
                <div className="border-none old-border-top old-border-2 old-border-lightgrey">
                  <span className="uppercase font-bold old-text-lightgrey old-mt-4 old-mb-4 old-d-block old-fs-14">
                    Sections
                  </span>

                  <ul className="font-bold uppercase old-mb-5 old-mb-md-6">
                    {Object.keys(sections).map((section) => {
                      const sectionsInView = Object.keys(sections).filter(
                        (s) => sections[s].inView,
                      );
                      const inView = sectionsInView[sectionsInView.length - 1] === section;

                      return (
                        <SectionLink
                          key={section}
                          name={section}
                          title={sections[section].title}
                          inView={inView}
                        />
                      );
                    })}
                  </ul>
                </div>

                <div className="old-d-flex old-flex-column old-gap-3">
                  <Button
                    color="primary"
                    type="submit"
                    trackingName="edit user profile"
                    size="lg"
                    isLoading={isPending}
                  >
                    Save and Return
                  </Button>

                  <Button
                    color="danger"
                    trackingName="delete user profile"
                    onClick={prepareDeleteAccount}
                    size="lg"
                    variant="flat"
                    isLoading={deleteLoading}
                  >
                    Delete Account
                  </Button>
                </div>
              </div>
            </Col>

            <Col xs={12} lg={8}>
              <InView
                as="div"
                className="border-t-2 border-primary-600 old-mb-6 old-mb-lg-section-margin flex flex-col gap-5"
                threshold={0.2}
              >
                <small className="uppercase font-bold text-primary-600 mt-5 block">
                  <Element name="icon" />
                  {sections.icon.title}
                </small>

                <ControlledAvatarInput
                  control={control}
                  name="icon"
                  defaultValue={user.icon}
                  avatarProps={{
                    name: user.username,
                  }}
                />
              </InView>

              <InView
                as="div"
                className="border-t-2 border-primary-600 old-mb-6 old-mb-lg-section-margin flex flex-col gap-5"
                onChange={(inView) => setSectionInView("basic", inView)}
                threshold={0.2}
              >
                <small className="uppercase font-bold text-primary-600 mt-5 block">
                  <Element name="basic" />
                  {sections.basic.title}
                </small>

                <Row>
                  <Col xs={12} md={6}>
                    <ControlledUsernameInput
                      control={control}
                      name="username"
                      defaultValue={user.username || ""}
                      label="Username"
                    />
                  </Col>

                  <Col className="font-roman old-fs-12 old-d-flex old-align-items-center">
                    <ul className="mb-0">
                      <li>Your username cannot be longer than 20 characters.</li>
                      <li>
                        A username can only contain alphanumeric characters (letters A-Z, numbers
                        0-9) with the exception of underscores.
                      </li>
                    </ul>
                  </Col>
                </Row>

                <Row className="gap-5">
                  <Col xs={12} md={6}>
                    <Input
                      {...register("given_name", {
                        maxLength: {
                          value: 30,
                          message: "First Name cannot be longer than 30 characters",
                        },
                      })}
                      label="First Name"
                      defaultValue={user.given_name}
                      isInvalid={!!errors.given_name}
                      errorMessage={errors.given_name?.message}
                    />
                  </Col>

                  <Col>
                    <Input
                      {...register("family_name", {
                        maxLength: {
                          value: 30,
                          message: "Last Name cannot be longer than 30 characters",
                        },
                      })}
                      label="Last Name"
                      defaultValue={user.family_name}
                      isInvalid={!!errors.family_name}
                      errorMessage={errors.family_name?.message}
                    />
                  </Col>
                </Row>

                <Row className="gap-5">
                  <Col xs={12} md={6}>
                    <Controller
                      name="dob"
                      defaultValue={user.dob}
                      control={control}
                      rules={{
                        validate: {
                          min: (v) =>
                            new Date(v) <
                              new Date(new Date().setFullYear(new Date().getFullYear() - 13)) ||
                            "You must be a minimum of 13 years old",
                        },
                      }}
                      render={({ field: { value, ...field } }) => (
                        <DatePicker
                          {...field}
                          defaultValue={value}
                          label="Date of Birth"
                          errorMessage={errors.dob?.message}
                          isInvalid={!!errors.dob}
                        />
                      )}
                    />
                  </Col>

                  <Col>
                    <Select
                      {...register("gender")}
                      label="Gender"
                      defaultSelectedKeys={[user.gender || ""]}
                      errorMessage={errors.gender?.message}
                      isInvalid={!!errors.gender}
                    >
                      <Select.SelectItem key="male">Male</Select.SelectItem>
                      <Select.SelectItem key="female">Female</Select.SelectItem>
                      <Select.SelectItem key="non_binary">Non Binary</Select.SelectItem>
                      <Select.SelectItem key="not_to_say">Prefer not to say</Select.SelectItem>
                    </Select>
                  </Col>
                </Row>

                <CountryPicker
                  {...register("country_code", {
                    validate: {
                      checkCountry: (v) =>
                        v === "" || iso3311a2.getCountries().includes(v) || "Not a valid country",
                    },
                  })}
                  defaultSelectedKey={user.country_code}
                  isInvalid={!!errors.country_code}
                  errorMessage={errors.country_code?.message}
                />
              </InView>

              <InView
                as="div"
                className="border-t-2 border-primary-600 old-mb-6 old-mb-lg-section-margin flex flex-col gap-5"
                onChange={(inView) => setSectionInView("about", inView)}
                threshold={0.2}
              >
                <small className="uppercase font-bold text-primary-600 mt-5 block">
                  <Element name="about" />
                  {sections.about.title}
                </small>

                <ControlledTextarea
                  rules={{
                    maxLength: {
                      value: 600,
                      message: "Description cannot be longer than 600 characters",
                    },
                  }}
                  control={control}
                  name="description"
                  placeholder="Write something about you! (in 600 words or less)"
                  maxRows={10}
                  minRows={5}
                  defaultValue={user.description}
                  errorMessage={errors.description?.message}
                  isInvalid={!!errors.description}
                />
              </InView>

              <InView
                as="div"
                className="border-t-2 border-primary-600 old-mb-6 old-mb-lg-section-margin flex flex-col gap-5"
                onChange={(inView) => setSectionInView("links", inView)}
                threshold={0.2}
              >
                <small className="uppercase font-bold text-primary-600 mt-5 block">
                  <Element name="links" />
                  {sections.links.title}
                </small>

                <Input
                  {...register("socials.website")}
                  label="Add Website URL"
                  defaultValue={socials?.website}
                  errorMessage={socialErrors.website?.message}
                  isInvalid={!!socialErrors.website}
                />
              </InView>

              <InView
                as="div"
                className="border-t-2 border-primary-600 old-mb-6 old-mb-lg-section-margin flex flex-col gap-5"
                onChange={(inView) => setSectionInView("socials", inView)}
                threshold={0.2}
              >
                <small className="uppercase font-bold text-primary-600 mt-5 block">
                  <Element name="socials" />
                  {sections.socials.title}
                </small>

                {socialFields.map((social) => (
                  <ControlledSocialInput
                    key={social}
                    name={`socials.${social}`}
                    control={control}
                    defaultValue={socials[social] ?? ""}
                    socialName={social}
                    icon={socialIcons[social]}
                  />
                ))}
              </InView>

              <InView
                as="div"
                className="border-t-2 border-primary-600 old-mb-6 old-mb-lg-section-margin flex flex-col gap-5"
                onChange={(inView) => setSectionInView("marketing", inView)}
                threshold={0.2}
                id="marketing"
              >
                <small className="uppercase font-bold text-primary-600 block mt-5">
                  <Element name="marketing" />
                  {sections.marketing.title}
                </small>

                <Controller
                  name="marketing"
                  control={control}
                  defaultValue={Object.keys(notifications).filter((k) => notifications[k])}
                  render={({ field }) => (
                    <CheckboxGroup {...field}>
                      {Object.keys(user.metadata.settings.notification_preferences).map(
                        (notification) => (
                          <Checkbox key={notification} radius="full" size="lg" value={notification}>
                            {notification.replace(/_/g, " ")}
                          </Checkbox>
                        ),
                      )}
                    </CheckboxGroup>
                  )}
                />
              </InView>

              <Button
                color="primary"
                size="lg"
                isLoading={isPending}
                trackingName="edut user profile"
                type="submit"
                fullWidth
                className="mb-12 uppercase"
              >
                Save Changes
              </Button>
            </Col>
          </Row>
        </Form>
      </Container>

      <ConfirmationModal
        show={showDeleteModal}
        promptText={<DeleteText entities={deletedEntities} />}
        confirmText="Delete Everything"
        onHide={() => setShowDeleteModal(false)}
        action={handleDeleteAccount}
        cancelProps={{
          color: "primary",
          variant: "solid",
        }}
        confirmProps={{
          color: "danger",
          variant: "flat",
        }}
      />
    </PageLayout>
  );
}
