import { useContext, useMemo } from "react";
import { Link, generatePath, useNavigate } from "react-router-dom";
import routes from "default/routes";
import {
  SubmissionCard,
  submissionStatusContent,
  proposalStatusContent,
  useSubmissions,
} from "features/submission";
import { briefPropTypes, briefStatusContent } from "features/brief";
import { UserContext } from "features/user";
import { Row, Col, CardGrid, AutoScrollLoader, Button } from "ui";
import PropTypes from "prop-types";

function SubmissionStatus({ text, icon, ctaText = "", ctaPath = "" }) {
  const Icon = icon;

  return (
    <div className="flex flex-col items-center justify-center grow-1 text-center p-8">
      <Icon className="!size-24 text-primary-500" />
      <p>{text}</p>

      {ctaText && ctaPath && (
        <Button color="primary" as={Link} to={ctaPath}>
          {ctaText}
        </Button>
      )}
    </div>
  );
}
SubmissionStatus.propTypes = {
  text: PropTypes.node.isRequired,
  icon: PropTypes.elementType.isRequired,
  ctaText: PropTypes.node,
  ctaPath: PropTypes.string,
};

export default function BriefWorkspace({ brief }) {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);

  const submissions = useSubmissions({
    user_id: user.username,
    brief_id: brief.id,
    type: ["submission"],
    sort: "newest",
  });
  const proposals = useSubmissions({
    user_id: user.username,
    brief_id: brief.id,
    type: ["proposal"],
    sort: "newest",
  });

  const submissionStatus = useMemo(() => {
    if (brief.type === "private") {
      if (proposals.all.length <= 0 || ["proposing", "selecting"].includes(brief.status))
        return "noProposal";
      if (!proposals.all[0].is_winner) return "locked";
    }
    if (submissions.all.length <= 0 && ["voting", "judging"].includes(brief.status))
      return "noSubmission";
    return brief.status;
  }, [proposals.all, submissions.all]);

  const { callToAction } = briefStatusContent(brief)[brief.status];
  const { icon: submissionIcon, text: submissionText } =
    submissionStatusContent()[submissionStatus];
  const { icon: proposalIcon, text: proposalText } =
    proposalStatusContent()[brief.status !== "proposing" ? "noProposal" : brief.status];

  return (
    <div data-testid="brief-workspace">
      <Row>
        {brief.type === "private" && (
          <Col xs={12} md={6}>
            <div className="flex flex-col bg-default-50 rounded-3xl px-8 pt-8 h-full">
              <span className="uppercase font-bold">Your Proposals</span>

              <CardGrid
                entries={proposals.all}
                card={SubmissionCard}
                sizing={{
                  lg: 12,
                  xl: 12,
                }}
                onClick={(submission) =>
                  navigate(
                    generatePath(routes.briefSubmission, {
                      id: brief.id,
                      submissionId: submission.id,
                    }),
                    {
                      state: {
                        scrollToTop: false,
                      },
                    },
                  )
                }
              />

              <AutoScrollLoader
                fetchNextPage={proposals.fetchNextPage}
                hasNextPage={proposals.hasNextPage}
                isLoading={proposals.isLoading || proposals.isFetchingNextPage}
                error={proposals.error}
                empty={!proposals.all.length}
                errorButtonVariant="black"
                textClassName=""
              >
                <SubmissionStatus
                  icon={proposalIcon}
                  text={proposalText}
                  ctaText={callToAction?.text}
                  ctaPath={callToAction?.path}
                />
              </AutoScrollLoader>
            </div>
          </Col>
        )}

        <Col xs={12} md={brief.type === "private" ? 6 : 12}>
          <div className="flex flex-col bg-default-50 rounded-3xl px-8 pt-8 h-full">
            <span className="uppercase font-bold">Your Submissions</span>

            <CardGrid
              entries={submissions.all}
              card={SubmissionCard}
              sizing={{
                ...(brief.type === "public" && {
                  md: 6,
                  lg: 4,
                  xl: 6,
                  xxxl: 4,
                }),
                ...(brief.type === "private" && { xs: 12 }),
              }}
              onClick={(submission) =>
                navigate(
                  generatePath(routes.briefSubmission, {
                    id: brief.id,
                    submissionId: submission.id,
                  }),
                  {
                    state: {
                      scrollToTop: false,
                    },
                  },
                )
              }
            />

            <AutoScrollLoader
              fetchNextPage={submissions.fetchNextPage}
              hasNextPage={submissions.hasNextPage}
              isLoading={submissions.isLoading || submissions.isFetchingNextPage}
              error={submissions.error}
              empty={!submissions.all.length}
              errorButtonVariant="black"
              textClassName=""
              className="grow flex"
            >
              <SubmissionStatus
                icon={submissionIcon}
                text={submissionText}
                ctaText={callToAction?.text}
                ctaPath={callToAction?.path}
              />
            </AutoScrollLoader>
          </div>
        </Col>
      </Row>
    </div>
  );
}
BriefWorkspace.propTypes = {
  brief: briefPropTypes.isRequired,
};
