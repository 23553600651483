import { useEffect } from "react";
import { PulseLoader } from "react-spinners";
import { useInView } from "react-intersection-observer";
import Button from "ui/buttons/Button";
import PropTypes from "prop-types";

function AutoScrollLoader({
  fetchNextPage,
  hasNextPage,
  isLoading,
  empty,
  error = null,
  className = "",
  textClassName = "uppercase old-fs-18 old-fs-md-20",
  errorButtonVariant = "white",
  children,
}) {
  const { ref, inView } = useInView({ rootMargin: "100%" });

  useEffect(() => {
    if (inView && !isLoading && fetchNextPage && hasNextPage) fetchNextPage();
  }, [inView, isLoading, fetchNextPage, hasNextPage]);

  if (error && !isLoading) {
    return (
      <div className={`text-center ${className}`}>
        <span className={`text-primary-500 old-mb-3 old-d-block ${textClassName}`}>
          {error.message || "Something went wrong"}
        </span>

        <Button onClick={fetchNextPage} color="primary" size="lg">
          Try Again
        </Button>
      </div>
    );
  }

  if (empty && !isLoading) {
    return (
      <div className={`text-center text-primary-500 ${className} ${textClassName}`}>{children}</div>
    );
  }

  return (
    <div ref={!error ? ref : undefined}>
      <PulseLoader
        loading={isLoading}
        speedMultiplier={0.5}
        size={15}
        color={errorButtonVariant}
        className="old-m-auto old-py-section-margin after:pb-12 after:block text-center old-loader text-foreground"
      />
    </div>
  );
}

AutoScrollLoader.propTypes = {
  children: PropTypes.node.isRequired,
  fetchNextPage: PropTypes.func.isRequired,
  hasNextPage: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  empty: PropTypes.bool.isRequired,
  className: PropTypes.string,
  textClassName: PropTypes.string,
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
  errorButtonVariant: PropTypes.string,
};

export default AutoScrollLoader;
