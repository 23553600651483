import { briefStatusContent } from "features/brief/data/briefData";
import briefPropTypes from "features/brief/schemas/briefSchema";
import Countdown from "react-countdown";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import PropTypes from "prop-types";

function CountdownRenderer({ days, hours, minutes, completed }) {
  return (
    <div
      className={`flex gap-3 text-sm uppercase font-bold flex-wrap ${completed ? "invisible" : "visible"}`}
      data-testid="countdown"
    >
      <span className="">
        {days} {days === 1 ? "Day" : "Days"}
      </span>
      <span className="">
        {hours} {hours === 1 ? "Hour" : "Hours"}
      </span>
      <span className="">{minutes} Mins</span>
    </div>
  );
}
CountdownRenderer.propTypes = {
  days: PropTypes.string.isRequired,
  hours: PropTypes.string.isRequired,
  minutes: PropTypes.string.isRequired,
  completed: PropTypes.bool.isRequired,
};

function BriefStatus({ brief, ...props }) {
  const { status, deadline } = briefStatusContent(brief)[brief.status];

  return (
    <div className="old-d-flex old-align-items-center w-full" {...props}>
      <div className="old-me-3">
        <span className="text-lg md:text-xl">{status}</span>
        {status !== "Accepting New Submissions" && (
          <div className="old-d-flex old-align-items-center old-mt-1">
            <Countdown
              date={deadline ? brief[deadline] : Date.now()}
              renderer={CountdownRenderer}
            />
          </div>
        )}
      </div>
      <div className="old-d-flex old-justify-content-end old-ms-auto">
        <HelpOutlineIcon className="!size-8" />
      </div>
    </div>
  );
}

BriefStatus.propTypes = {
  brief: briefPropTypes.isRequired,
};

export default BriefStatus;
